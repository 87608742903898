import React from "react";
import styled from "styled-components";
import sanitizeHtml from "sanitize-html";

import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon,
    Close as CloseIcon
} from "@material-ui/icons";

import getLogger, { LogGroup } from "js/core/logger";
import { Key } from "js/core/utilities/keys";
import { formatDuration } from "../helpers/formatDuration";

import { LoadingPage } from "legacy-js/react/components/LoadingPage";
import Thumbnail from "./Thumbnail";
import Player from "../Player";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";

const logger = getLogger(LogGroup.PLAYER);

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: #222;

    div {
        box-sizing: border-box;
    }
`;

const HeaderContainer = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const SlideAndNotesContainer = styled.div`
    height: calc(100vh - 160px);
    width: 100%;
    display: flex;
    flex-flow: row;
    padding: 0px 10px;
`;

const SlideContainer = styled.div`
    position: relative;
    height: 100%;
    width: 66%;
    background: #333;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ControlBarContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f1f1f1;
    font-size: 20px;
    padding: 10px;
`;

const ControlBarDateTimeContainer = styled.div`
    flex-grow: 2;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
`;

const ControlBarControlsContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
`;

const SlideIndex = styled.div`
    user-select: none;
    text-transform: uppercase;
    font-size: 13px;
    color: #ccc;
    margin-left: 8px;
    margin-right: 8px;
`;

const ControlBarTimersContainer = styled.div`
    flex-grow: 2;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;

    >span:first-child {
        margin: 0px 10px;
    }
    >span:last-child {
        margin: 0px 10px;
    }
`;

const SlideNotesContainer = styled.div`
    height: 100%;
    width: 34%;
    background: #f1f1f1;
    padding: 10px 20px;
    margin-left: 20px;
    font-size: 24px;
    line-height: 1.5;
    overflow-y: auto;
    white-space: pre-line;
    overflow-wrap: break-word;
`;

const ThumbnailsContainer = styled.div`
    height: 120px;
    width: 100%;
    background-color: #222;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    padding: 10px 0px;

    >div:last-child {
        padding-right: 10px;
    }
    >div:first-child {
        padding-left: 10px;
    }

    ::-webkit-scrollbar {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background-color: #aaa;
        border: 3px solid #222;
    }
`;

const ThumbnailContainer = styled.div`
    height: 100%; 
    padding: 0px 5px;

    >div {
        height: 100%;
    }
`;

function getStyledIconButton(Icon, style = {}) {
    return styled(Icon).attrs(() => ({ style }))`
        font-size: 24px;
        cursor: pointer;

        &:hover {
            color: #11a9e2 !important;
        }
    `;
}
const PrevSlideButton = getStyledIconButton(NavigateBeforeIcon);
const NextSlideButton = getStyledIconButton(NavigateNextIcon);

const CloseButton = getStyledIconButton(CloseIcon, { marginRight: "7px", color: "#ccc" });

class PresenterView extends React.Component {
    constructor() {
        super();

        this.state = {
            secondsOnCurrentSlide: 0,
            secondsPresenting: 0,
            slidesLoaded: false
        };

        this.presentingInterval = null;
        this.currentSlideInterval = null;

        this.currentSlideThumbnailRef = React.createRef();
        this.playerRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("keydown", this.onKeyDown);

        this.loadSlides()
            .then(() => {
                this.presentingInterval = setInterval(() => this.setState(({ secondsPresenting }) => ({ secondsPresenting: secondsPresenting + 1 })), 1000);
                this.currentSlideInterval = setInterval(() => this.setState(({ secondsOnCurrentSlide }) => ({ secondsOnCurrentSlide: secondsOnCurrentSlide + 1 })), 1000);
            });
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onKeyDown);

        clearInterval(this.presentingInterval);
        clearInterval(this.currentSlideInterval);
    }

    componentDidUpdate(prevProps) {
        const { currentSlideIndex } = this.props;

        if (currentSlideIndex !== prevProps.currentSlideIndex) {
            this.setState({ secondsOnCurrentSlide: 0 });
            // The view may have not been rendered yet
            if (this.currentSlideThumbnailRef.current) {
                this.currentSlideThumbnailRef.current.scrollIntoView({ behavior: "smooth" });
            }

            this.playerRef?.current?.goToSlide(currentSlideIndex, true);
        }
    }

    loadSlides() {
        const { slides } = this.props;

        return Promise.all(Object.values(slides).map(slide => slide.load()))
            .then(() => this.setState({ slidesLoaded: true }))
            .catch(err => {
                logger.error(err, "PresenterView loadSlides() failed");
            });
    }

    trackSlideNavigation = ({ method, deviceType }) => {
        const { analytics, username } = this.props;
        return analytics.trackPlaybackNavigated({ method: method, deviceType: deviceType, username });
    }

    onKeyDown = event => {
        const { rootPlayerView } = this.props;

        const key = event.which;

        if ([
            Key.RIGHT_ARROW,
            Key.DOWN_ARROW,
            Key.PAGE_DOWN,
        ].includes(key)) {
            event.stopPropagation();
            rootPlayerView.advanceToSlide(1);
            this.trackSlideNavigation({ method: "tab", deviceType: "desktop" });
        } else
        if ([
            Key.LEFT_ARROW,
            Key.UP_ARROW,
            Key.PAGE_UP,
        ].includes(key)) {
            event.stopPropagation();
            rootPlayerView.advanceToSlide(-1);
            this.trackSlideNavigation({ method: "tab", deviceType: "desktop" });
        }
    }

    advancePresenter = (offsetFromCurrentSlide, suppressAnimation = false) => {
        this.playerRef?.current?.advanceToSlide(offsetFromCurrentSlide, suppressAnimation);
    }

    render() {
        const {
            contextType,
            currentSlideIndex,
            currentSlidePlaybackStageIndex,
            presentation,
            slides,
            slidesMetadata,
            rootPlayerView,
        } = this.props;
        const {
            secondsOnCurrentSlide,
            secondsPresenting,
            slidesLoaded
        } = this.state;

        const skippedSlideIds = Object.entries(slidesMetadata).map(([slideId, { isSkipped }]) => isSkipped ? slideId : null).filter(slideId => !!slideId);
        const slideIds = presentation.getSips(skippedSlideIds);
        const currentSlideId = slideIds[currentSlideIndex];
        const currentSlide = slides[currentSlideId];

        const slideNotes = sanitizeHtml(currentSlide.get("slide_notes") || "No notes", {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(["strike"])
        });

        const Thumbnails = [];
        if (slidesLoaded) {
            slideIds.forEach((slideId, slideIndex) => {
                const slide = slides[slideId];
                const playbackStagesCount = slide.getPlaybackStageCount();
                [...Array(playbackStagesCount)].forEach((_, playbackStageIndex) => {
                    const isCurrent = slideId === currentSlideId && playbackStageIndex === currentSlidePlaybackStageIndex;
                    Thumbnails.push(
                        <ThumbnailContainer
                            ref={isCurrent ? this.currentSlideThumbnailRef : null}
                            key={`${slideId}-${playbackStageIndex}`}
                        >
                            <Thumbnail
                                presentation={presentation}
                                slide={slides[slideId]}
                                slideMetadata={slidesMetadata[slideId]}
                                hilited={isCurrent}
                                outlineWidth={4}
                                imageSize="small"
                                clickable={true}
                                onClick={() => rootPlayerView.goToSlide(slideIndex, true, playbackStageIndex)}
                                playbackStageIndex={playbackStageIndex}
                                shouldLoadThumbnail={true}
                                width="175px"
                            />
                        </ThumbnailContainer>
                    );
                });
            });
        }

        return (
            <Container>
                {slidesLoaded && <>
                    <HeaderContainer>
                        <CloseButton onClick={() => window.close()} />
                    </HeaderContainer>
                    <SlideAndNotesContainer>
                        <SlideContainer>
                            <Player
                                ref={this.playerRef}
                                parentPresenter={this}
                                contextType={contextType}
                                presentationOrLinkId={presentation.id}
                                startSlideIndex={currentSlideIndex}
                            />
                            <ControlBarContainer>
                                <ControlBarDateTimeContainer>{new Date().toString("hh:mm:ss tt")}</ControlBarDateTimeContainer>
                                <ControlBarControlsContainer>
                                    <PrevSlideButton onClick={() => rootPlayerView.advanceToSlide(-1)} />
                                    <SlideIndex>{currentSlideIndex + 1}&nbsp;/&nbsp;{slideIds.length}</SlideIndex>
                                    <NextSlideButton onClick={() => rootPlayerView.advanceToSlide(1)} />
                                </ControlBarControlsContainer>
                                <ControlBarTimersContainer>
                                    <span>{formatDuration(secondsOnCurrentSlide)}</span>
                                    <span>/</span>
                                    <span>{formatDuration(secondsPresenting)}</span>
                                </ControlBarTimersContainer>
                            </ControlBarContainer>
                        </SlideContainer>
                        <SlideNotesContainer dangerouslySetInnerHTML={{ __html: slideNotes }} />
                    </SlideAndNotesContainer>
                    <ThumbnailsContainer>
                        {Thumbnails}
                    </ThumbnailsContainer>
                </>}
                {!slidesLoaded && <LoadingPage />}
            </Container>
        );
    }
}

export default PresenterView;
